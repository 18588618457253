<template>
  <div class="my-product-container">
    <div class="my-product-publish-btn" @click="clickPublishProductBtnHandler()">
        <p class="my-product-publish-btn-text">我要卖号</p>
    </div>
    <product-item></product-item>
  </div>
</template>

<script>
import ProductItem from './product-item.vue'
export default {
    name:'MyCenterMyProduct',
    components:{
        ProductItem
    },
    methods:{
        //点击发布商品操作
        clickPublishProductBtnHandler(){
            this.$router.push('/publishProduct',()=>{})
        }
    }
}
</script>

<style lang="less" scoped>
.my-product-container{
    background: #FFFFFF;
    margin-top: 1px;
    padding-top: 20px;
    padding-bottom: 30px;
    border-radius: 0px 0px 8px 8px;
    .my-product-publish-btn{
        margin-left: 20px;
        width: 160px;
        height: 50px;
        background: #F02233;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        .my-product-publish-btn-text{
            height: 16px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 16px;
            text-align: right;
            font-style: normal;
        }
    }
}
</style>